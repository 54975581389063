import { FaCircle, FaDownload } from 'react-icons/fa'

import { IconContext } from 'react-icons'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'relative'
  },
  frontPage: {
    [theme.breakpoints.up('md')]: {
      marginTop: -64
    }
  },
  logoBackground: {
    color: theme.palette.primary.light,
    display: 'block',
    margin: '0 auto',
    height: '12vh',
    width: 'auto'
  },
  logo: {
    color: 'white',
    display: 'block',
    margin: '0 auto',
    position: 'relative',
    zIndex: 1,
    top: '8.5vh',
    height: '6vh',
    width: 'auto'
  }
})

const Logo = ({ classes, frontPage }) => (
  <div className={frontPage ? `${classes.root} ${classes.frontPage}` : classes.root}>
    <IconContext.Provider value={{ className: classes.logo, size: 50 }}>
      <FaDownload />
    </IconContext.Provider>
    <IconContext.Provider value={{ className: classes.logoBackground, size: 100 }}>
      <FaCircle />
    </IconContext.Provider>
  </div>
)

export default withStyles(styles)(Logo)
