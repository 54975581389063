import BlogLayout from '../components/containers/BlogLayout'
import Head from '../components/informational/Head'
import { Link } from 'gatsby'
import MaterialLink from '@material-ui/core/Link'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Blog = ({ classes }) => (
  <>
    <Head title='The Website Downloader Blog' description='Read the latest info about downloading websites from The Website Downloader Blog' />

    <BlogLayout>
      <Typography variant='h1' gutterBottom>
        The Website Downloader Blog
      </Typography>
      <Typography variant='h2' gutterBottom>
        <MaterialLink component={Link} to='/download-source-code-website/'>How to download source code of any website?</MaterialLink>
      </Typography>
      <Typography variant='body1' color='textSecondary' gutterBottom>
        In order to download a website all you need is the website address (URL, for eg. apple.com or google.com), an internet connection and WebsiteDownloader.io
      </Typography>
      <Typography variant='body1' color='textSecondary' gutterBottom>
        WebsiteDownloader.io is a great tool that lets you <MaterialLink component={Link} to='/'>download the source code of any website</MaterialLink> which includes the HTML files, static assets like JS (Javascript), CSS, Images... <MaterialLink component={Link} to='/download-source-code-website/'>Continue reading</MaterialLink>
      </Typography>
    </BlogLayout>
  </>
)

export default Blog
